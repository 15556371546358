<template>
<div class="container m-t-30">

    <div v-if="isInitializing" class="loading">
        <unc-loader></unc-loader>
    </div>

    <div v-else-if="showNotFound" class="row animated fadeIn">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="jumbotron text-center">
                <h2>Usuario no encontrado.</h2>
                <p>No se ha encontrado ningún usuario con el identificador recibido o el mismo expiró.</p>
                <button @click.prevent="goBack()" type="submit" class="btn btn-primary btn-lg m-t-10">Volver</button>
            </div>
        </div>
    </div>
    
    <div v-else class="row animated fadeIn">
        <div class="col-md-3 col-sm-2 col-xs-12"></div>

        <div class="col-md-6 col-sm-8 col-xs-12">

            <div class="card animated fadeIn">
                <div class="card-header ch-alt">
                    <h2>{{ titulo }} <small>Completá el formulario para realizar el cambio de contraseña.</small> </h2>
                </div>

                <div class="card-body card-padding">

                    <form @submit.prevent="validateBeforeSubmit">

                        <label>CONTRASEÑA</label>

                        <p>Ingresá una contraseña válida. <a href="" @click.prevent="showRulesPassword=!showRulesPassword">{{ showRulesPassword ? 'Ocultar' : 'Mostrar' }} reglas para contraseñas.</a></p>

                        <div v-if="showRulesPassword" class="alert alert-info" role="alert">
                            <p><strong>Reglas para contraseñas</strong></p>
                            <p>La contraseña debe respetar las siguientes reglas:</p>
                                <ul>
                                    <li>Tener como mínimo 10 caracteres de longitud.</li>
                                    <li>Contener letras y números.</li>
                                    <li>Contener al menos un número.</li>
                                    <li>Contener al menos una letra mayúscula.</li>
                                    <li>Contener al menos una letra minúscula.</li>
                                    <li>No repetir una contraseña anterior.</li>
                                </ul>
                        </div>

                        <div class="form-group" :class="{'has-error': errors.has('password')}">
                            <div class="input-group">
                                <div class="fg-line text-left">
                                    <label for="password">Ingresá la nueva contraseña</label>
                                    <input :type="passwordFieldType" v-model="item.password" id="password" name="password" v-validate="'required|isYuliPassword|min:10'" class="form-control" placeholder="Ingresá una contraseña válida..." value=""> 
                                </div>
                                <span v-if="passwordFieldType=='password'" @click.prevent="showPassword(true)" class="input-group-addon last"><i class="zmdi zmdi-eye-off zmdi-hc-2x"></i></span>
                                <span v-else @click.prevent="showPassword(false)" class="input-group-addon last"><i class="zmdi zmdi-eye zmdi-hc-2x"></i></span>
                            </div>
                            <small v-if="errors.has('password')" class="help-block text-left">{{ errors.first('password') }}</small>
                        </div>

                        <div class="form-group" :class="{'has-error': errors.has('passwordConfirmation') || showValidationErrorPasswordConfirmation}">
                            <div class="input-group">
                                <div class="fg-line text-left">
                                    <label for="passwordConfirmation">Repetir contraseña:</label>
                                    <input :type="passwordConfirmationFieldType" @keyup="showValidationErrorPasswordConfirmation=false" v-model="item.passwordConfirmation" id="passwordConfirmation" name="passwordConfirmation" v-validate="'required'" class="form-control" placeholder="Ingresá nuevamente tu contraseña..." value=""> 
                                </div>
                                <span v-if="passwordConfirmationFieldType=='password'" @click.prevent="showPassword(true, true)" class="input-group-addon last"><i class="zmdi zmdi-eye-off zmdi-hc-2x"></i></span>
                                <span v-else @click.prevent="showPassword(false,true)" class="input-group-addon last"><i class="zmdi zmdi-eye zmdi-hc-2x"></i></span>
                            </div>
                            <small v-if="errors.has('passwordConfirmation')" class="help-block text-left">{{ errors.first('passwordConfirmation') }}</small>
                            <small v-if="showValidationErrorPasswordConfirmation" class="help-block">Las contraseñas no coinciden</small>
                        </div>

                        <div class="text-right">
                            <button @click.prevent="goBack()" type="button" class="btn btn-link m-t-10 waves-effect">Volver</button>
                            <button type="submit" class="btn btn-primary m-t-10 waves-effect">Siguiente</button>
                        </div>                  
                    </form>
            
                </div> 
            </div>
        
        </div>
        <div class="col-md-3 col-sm-2 col-xs-12"></div>

    </div>       

</div>
</template>

<script>
    import { HttpYULI, HttpFRONT, swal } from '../../share.js';

    export default {
        data() {
            return {
                titulo: 'RECUPERAR CONTRASEÑA',
                isInitializing: true,
                item: {
                    password: '',
                    passwordConfirmation: '',
                },
                user: '',
                showNotFound: false,
                showRulesPassword: true,
                showValidationErrorPasswordConfirmation: false,
                passwordFieldType: 'password',
                passwordConfirmationFieldType: 'password'
            }
        },
        props: ['token'],  
        mounted() {
            if(this.token) {
                this.getData();
            } else {
                this.showNotFound = true;
                this.isInitializing = false;
            }
        },
        methods: {
            getData() {
                let self = this;

                HttpYULI.get(`tokens/${this.token}/user`)
                .then((response) => {

                    if (response.status === 200) {
                        self.user = response.data.user;
                    } else {
                        self.showNotFound = true;
                    }
                }).catch(() => {
                    self.showNotFound = true;
                }).finally(() => {
                    self.isInitializing = false;
                });
                
            },
            validateBeforeSubmit() {
            
                this.$validator.validateAll().then(() => {
                    if (!this.errors.any()) {
                        if(this.validatePasswordConfirmation(this.item.password, this.item.passwordConfirmation)) {
                            this.save();
                        } else {
                            this.showValidationErrorPasswordConfirmation = true;
                        }
                    }
                }).catch(() => {
                    swal({title: 'Algo salió mal', text: 'Tus datos no fueron registrados.', type: 'warning' });
                });
            
            },
            validatePasswordConfirmation(password, passwordConfirmation){
                return password == passwordConfirmation ? true : false;
            },
            save() {
                let self = this;
                self.isLoading = true;

                let userToSend = {
                    token: self.token,
                    password: self.item.password
                }

                HttpFRONT.patch(`usuarios/${self.user._id}/recuperar_clave`, userToSend)
                .then(response => {

                    if (response.status === 201 || response.status === 200) {
                        swal({title:  'Contraseña Recuperada', text: `Tu contraseña fue recuperada satisfactoriamente.`, type: 'success' });
                        this.$router.push('/');
                    }
                    
                }).catch(error => {
                    let status = error.response.status;
                    
                    switch (status) {
                        case 401:
                            swal({
                                title: 'Acceso Denegado',
                                text: 'No posee permisos para acceder a este servicio.',
                                type: 'warning',
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                            });
                            break;
                        case 422:
                            swal({
                                title: 'No pudimos cambiar la contraseña',
                                text: 'Tu contraseña no cumple con las políticas de seguridad.',
                                type: 'warning',
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                             });
                            break;
                        default:
                            swal({
                                title: 'Algo salió mal',
                                text: 'Servicio temporalmente no disponible.',
                                type: 'warning',
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                            });
                            break;
                    }


                }).finally(() => {
                    self.isLoading = false;
                }); 

            },
            goBack(){
                this.$router.push('/');
            },
            showPassword(show = true, confirmation = false) {

                let fieldType = show ? 'text' : 'password';

                if (confirmation) {
                    this.passwordConfirmationFieldType = fieldType;
                } else {
                    this.passwordFieldType = fieldType;
                }
        }

        }
    }
</script>

<style lang="scss" scoped>

    .input-group .fg-line {
        padding: 0 0px;
    }

</style>
