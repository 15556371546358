<template>
    <div class="container m-t-30">
        <div v-if="isInitializing">
            <br>
            <div style="text-align:center"></div><br>
            <div style = "margin:0px 48%!important" class="loading" >
            <unc-loader></unc-loader>
            </div>
        </div>
  
        <div v-else class="row animated fadeIn card">
            <div class="col-md-12 col-sm-12 col-xs-12 card-header">
                <h3 class="m-t-0 m-l-5">Contactos de gestión de Usuarios UNC</h3>
                <span class="m-l-5">Si tenés algún problema con tu usuario, buscá el correo de contacto de tu dependencia o facultad y enviales un mensaje.</span>
                <div class="my-listview">
                    <div class="row my-listview-item th hidden-xs">
                    <div class="col-sm-6 col-xs-12">Dependencia</div>
                    <div class="col-sm-6 col-xs-12">Correo</div>
                </div>

                <div class="row my-listview-item card-body" style="margin-bottom: 0; padding-bottom: 0%;" v-for="item in admins" :key="item.correo">
                    <div class="col-sm-6 col-xs-12">
                        <p class="hidden-sm hidden-md hidden-lg hidden-xl">
                            Dependencia: {{ item.dependencia ? item.dependencia : 'Sin datos' }}
                        </p>
                        <p class="hidden-xs">
                            {{ item.dependencia ? item.dependencia : 'Sin datos' }}
                        </p>
                    </div>

                    <div class="col-sm-6 col-xs-12">
                        <p class="hidden-sm hidden-md hidden-lg hidden-xl">
                            Correo: {{ item.correo ? item.correo : 'Sin datos' }}
                        </p>
                        <p class="hidden-xs">
                            {{ item.correo ? item.correo : 'Sin datos' }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
  </template>
  
<script>
    import { HttpFRONT, swal } from "../../share.js";

    export default {
    data() {
        return {
            isInitializing: true,
            showNotFound: false,
            admins: {}
        };
    },
    computed: {
    },
    async mounted() {
        await this.getData();
    },

    methods: {
        async getData() {
            try {
                const response = await HttpFRONT.get(`dataadmins`);
                if (response.status === 200) {
                    this.isInitializing = false;
                    this.admins = response.data;
                }
            } catch (error) {
                this.isInitializing = true;
                const message = (error.response && error.response.data) || "Servicio temporalmente no disponible";
                await swal({
                    title: "Algo salió mal",
                    text: message,
                    type: "warning"
                });
            }
        },
    }
    };
</script>

<style lang="scss" scoped>
    .my-listview-item {

        position: relative;
        display: block;
        background-color: #ffffff;
        border-bottom: 1px solid #e9e9e9;
    }
</style>
  

